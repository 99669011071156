<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          SIA
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            Daftar Sekarang
          </b-card-title>
          <b-card-text class="mb-2">
            Belum bergabung menjadi anggota kami? Daftar Sekarang!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{invalid}">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- name -->
              <b-form-group label="Nama Lengkap" label-for="register-name">
                <validation-provider
                  #default="{ errors }"
                  name="Fullname"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="userFullName"
                    name="register-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Adyaksa Tridarma"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group label="Username" label-for="register-username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="adyaksa"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="adyaksa@adil.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="site_key"
                  @verify="verifyMethod"
                  @expired="expiredMethod"
                />
              </b-form-group>

              <!-- <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  Saya setuju dengan
                  <b-link>kebijakan dan ketentuan privasi</b-link>
                </b-form-checkbox>
              </b-form-group> -->

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Daftar
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Saya sudah memiliki akun!</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Masuk sekarang</span>
            </b-link>
          </p>
        </b-col>
        <b-overlay :show="processing" no-wrap>
          <template #overlay>
            <b-spinner variant="primary" label="Text Centered" />
          </template>
        </b-overlay>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { VueRecaptcha } from 'vue-recaptcha'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  // BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { api } from '@/hap-sia/setup'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    // BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner,
    VueRecaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userFullName: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      processing: false,
      recaptcha: '',
      site_key: process.env.VUE_APP_SIA_RECAPTCHA_SITEKEY,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async register() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          try {
            this.$refs.recaptcha.reset()
            this.processing = true
            // await new Promise(r => setTimeout(r, 2000))
            await api().post('akun/register', {
              nama_lengkap: this.userFullName,
              email: this.userEmail,
              username: this.username,
              password: this.password,
              recaptcha_token: this.recaptcha,
            })
            this.$toast(
              {
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${this.userFullName || this.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully register in as ${this.username}. Now you can login!`,
                },
              },
              {
                timeout: 10000,
              },
            )
            this.$router.replace({ name: 'login' })
          } catch (error) {
            // alert(error)
            this.$toast(
              {
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                timeout: 8000,
              },
            )
          }
          this.processing = false
        }
      })
    },
    verifyMethod(response) {
      this.recaptcha = response
    },
    expiredMethod() {
      this.recaptcha = ''
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
